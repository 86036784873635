<template>
  <div class="nav-wrapper">
    <div class="brand">
      <img
        @click="goBack"
        class="brand-image"
        src="@/assets/logo_.png"
        alt=""
      />
      <div class="brand-title">
        <h4>PPDB ONLINE</h4>
        <small>Pondok Pesantren Darun Najah</small>
      </div>
    </div>
    <div class="nav-menu" v-if="!isMobile">
      <template v-if="token == null || token == 'undefined'">
        <el-button
          @click="goLogin"
          round
          :loading="loading"
          type="primary"
          icon="el-icon-user"
          >{{ isMobile ? "" : "Log in" }}</el-button
        >
        <el-button
          @click="goRegister"
          round
          :loading="loading"
          type="default"
          icon="el-icon-plus"
          >{{ isMobile ? "" : "Register" }}</el-button
        >
      </template>
      <template v-else>
        <el-button @click="goRiwayat" plain icon="el-icon-s-order" round>
          Riwayat Pendaftaran
        </el-button>
        <el-button
          @click="goLogout"
          :loading="loading"
          round
          type="danger"
          icon="el-icon-right"
        >
          Log out
        </el-button>
      </template>
    </div>
    <el-popover v-if="isMobile" placement="bottom-end" title="Menu" width="200" trigger="hover">
      <el-button size="small" icon="el-icon-menu" slot="reference"></el-button>
      <div class="mobile-menu">
        <template v-if="token == null || token == 'undefined'">
          <el-button
            @click="goRegister"
            :loading="loading"
            type="default"
            icon="el-icon-plus"
            >Register</el-button
          >
          <el-button
            @click="goLogin"
            :loading="loading"
            type="primary"
            icon="el-icon-user"
            >Log in</el-button
          >
        </template>
        <template v-else>
          <el-button @click="goRiwayat" plain icon="el-icon-s-order">
            Riwayat Pendaftaran
          </el-button>
          <el-button
            @click="goLogout"
            :loading="loading"
            type="danger"
            icon="el-icon-right"
          >
            Log out
          </el-button>
        </template>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: "navigation-components",
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
  },
  methods: {
    goBack() {
      this.$router.push({ name: "Home" });
    },
    goRiwayat() {
      this.$router.push({ name: "Riwayat" });
    },
    goLogin() {
      this.loading = true;
      this.$router.push({ name: "Login" });
      this.loading = false;
    },
    goRegister() {
      this.loading = true;
      this.$router.push({ name: "Register" });
      this.loading = false;
    },
    goLogout() {
      this.loading = true;
      localStorage.removeItem("token");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  &-wrapper {
    position: sticky;
    top: 0;
    min-height: 50px;
    border-bottom: 1px solid darken(white, 10%);
    background-image: linear-gradient(
      darken(white, 10),
      transparentize(white, 0.8)
    );
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $space / 2;
    z-index: 10;
    padding: $space 4% !important;

    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: white;
      opacity: 0.5;
      content: "";
      z-index: -1;
    }
  }

  &-menu {
    display: flex;
    gap: $space;
    align-items: center;

    ul {
      list-style: none;
      display: inline-flex;
      gap: $space * 2;
      font-size: 0.875rem;
    }

    &.mobile {
      gap: 0;
      flex-direction: column;
    }
  }
}

.btn-login {
  border: 1px solid darken(white, 10);
}

.brand {
  display: flex;
  align-content: center;
  justify-content: center;
  gap: $space / 2;
  &-image {
    height: 40px;
    cursor: pointer;
  }
  &-title {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    h4 {
      margin: 0;
      font-weight: bold;
    }
  }
}

.mobile-menu{
  padding-top: $space;
  display: flex;
  flex-direction: column;
  gap: $space;

  .el-button{
    margin: 0;
  }
}
</style>
