import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

import UniversalSocialauth from 'universal-social-auth'

import DeviceCheck from "@/mixins/deviceCheck";
import FormatRupiah from "@/mixins/rupiah";

// Layout
import Layouts from "./layouts/Layouts.vue";
import Auth from "./layouts/Auth.vue";


import "./plugins/element.js";

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

Vue.component("default-layout", Layouts);
Vue.component("auth-layout", Auth);
Vue.mixin(DeviceCheck);
Vue.mixin(FormatRupiah);

const allowCrossDomain = function (req, res, next) {
  res.header("Access-Control-Allow-Origin", "*");
  res.header("Access-Control-Allow-Methods", "*");
  res.header("Access-Control-Allow-Headers", "*");
  next();
};

const options = {
  providers: {
    // apple: {
    //   nonce: '**************',
    //   state: '**************',
    //   clientId: '**************',
    //   redirectUri: 'https://myapp.com/auth/github/callback'
    // },
    // github: {
    //   clientId: '78bfc07e1f25b5e22c26',
    //   redirectUri: 'https://diadal.com.ng/auth/github/callback'
    // },
    // twitch: {
    //   clientId: 'qi4vbk30uuvuqigcd5ioq3egxf67m8',
    //   redirectUri: 'https://diadal.com.ng/auth/twitch/callback'
    // },
    google: {
      clientId: "366558832865-3l92en3b3n7edh75otbd3ev2mrahvqh1.apps.googleusercontent.com",
      redirectUri: 'https://ppdb.ppaidarunnajah.id/login/callback/google'
    },
    // facebook: {
    //   clientId: '************',
    //   redirectUri: 'https://myapp.com/auth/facebook/callback'
    // },
    // twitter: {
    //   url: 'https://myapp.com/auth/twitter',
    //   clientId: '********',
    //   redirectUri: 'https://myapp.com/auth/twitter/callback'
    // }
  }
}

const Oauth = new UniversalSocialauth(axios, options)

Vue.prototype.$Oauth = Oauth

new Vue({
  router,
  store,
  allowCrossDomain,
  DeviceCheck,
  render: (h) => h(App),
}).$mount("#app");
