<template>
  <section
    class="banner"
    :class="isMobile ? 'mobile' : ''"
    :style="{ 'background-image': 'url(' + background + ')' }"
  >
    <div class="greeting">
      <span class="greeting-welcome">Selamat Datang di Sistem PPDB Online</span>
      <h1 class="greeting-title">YAYASAN MA'HAD DARUN NAJAH AS-SALAFY</h1>
      <small>Tahun Ajaran 2024/2025</small>
      <el-button
        :disabled="status != 'buka'"
        @click="onClick"
        class="button-registration"
        type="primary"
        round
        >Daftar Sekarang</el-button
      >
    </div>
    <div v-if="!isMobile" class="ilustration">
      <video
        width="540"
        height="290"
        controls
        autoplay
        muted
        :src="video"
        type="video/mp4"
      ></video>
    </div>
  </section>
</template>

<script>
export default {
  name: "banner-components",
  props: {
    video: String,
    background: String,
    status: String,
    onClick: Function,
  },
};
</script>

<style lang="scss" scoped>
.banner {
  height: 350px;
  background-position: center center;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  color: white;
  gap: $space * 2;
  padding: 4%;
  box-shadow: 0 -10px 10px rgba($color: #000000, $alpha: 0.1);

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparentize($dark-color, 0.6);
    z-index: 0;
  }
  &:before {
    content: "";
    position: absolute;
    background-color: darken(white, 16);
    bottom: -1px;
    left: 0;
    right: 0;
    height: 20px;
    border-top-right-radius: 100px;
    border-top-left-radius: 100px;
    z-index: 2;
    border-top: 10px solid $green-color;
  }
}

.greeting {
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 1;
  max-width: 50%;
  min-width: auto;

  &-title {
    font-size: 2.5rem;
    margin: 0;
  }

  &-welcome {
    font-size: 1.5rem;
    font-weight: 300;
  }

  &-desc {
    margin-top: $space * 3;
    margin-bottom: $space;
    font-size: 1rem;
    width: 100%;
  }

  small {
    align-items: flex-end;
  }
}

.ilustration {
  height: 300px;
  width: 550px;
  flex: 1;
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 4%;
  background-color: darken(white, 90);
  border: 5px solid black;
  border-top-left-radius: $space;
  border-top-right-radius: $space;
  align-items: center;
  justify-content: center;
  display: flex;
  video {
    outline: none !important;
  }
}

@media #{$mq-large} {
  .banner {
    padding: 4% 18% !important;
  }
  .ilustration {
    right: 18%;
  }
}

.mobile {
  .banner {
    flex-direction: column;
    justify-content: center;
  }
  .greeting {
    max-width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    &-welcome {
      font-size: 1rem;
    }
    &-title {
      font-size: 1.2rem;
    }
  }
}
</style>