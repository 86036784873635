<template>
  <section class="persyaratan_biaya" :class="isMobile ? 'mobile' : ''">
    <div class="biaya">
      <h1 class="biaya__title">BIAYA PENDAFTARAN</h1>
      <el-table class="table" :data="biaya" border>
        <el-table-column min-width="200" prop="lembaga" label="Lembaga Pendidikan">
        </el-table-column>
        <el-table-column min-width="120" prop="jenis_siswa" label="Jenis Siswa">
          <template slot-scope="item">
            <el-tag :type="item.row.jenis_siswa == 'Baru' ? 'success' : 'warning'">{{item.row.jenis_siswa}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column min-width="200" align="right" label="Biaya Pendaftaran">
          <template slot-scope="scope">
            <span>{{ formatRupiah(scope.row.nominal) }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="persyaratan">
      <h1 class="persyaratan__title">PERSYARATAN</h1>
      <el-table class="table" :data="persyaratan" border>
        <el-table-column min-width="100" prop="nama" label="Deskripsi"> </el-table-column>
        <el-table-column prop="jumlah" label="Keterangan"> </el-table-column>
      </el-table>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    biaya: Array,
    persyaratan: Array,
  },
};
</script>

<style lang="scss" scoped>
.persyaratan_biaya {
  background-color: transparentize(darken(white, 25), 0.4);
  position: relative;
  padding: 4%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  gap: $space;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 1;
    background-image: url("~@/assets/Pattern.png");
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: contain;
  }

  .persyaratan,
  .biaya {
    background-color: white;
    border-radius: $space;
    padding: 0 $space $space $space;
  }

  .persyaratan {
    flex: 1;
    &__title {
      font-size: 2rem;
      margin: $space * 2 0;
      padding-bottom: $space;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 80px;
        height: 5px;
        background-color: $green-color;
      }
    }
  }
  .biaya {
    flex: 1;

    &__title {
      font-size: 2rem;
      margin: $space * 2 0;
      padding-bottom: $space;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 80px;
        height: 5px;
        background-color: $green-color;
      }
    }
    &__title {
      font-size: 2rem;
      margin: $space * 2 0;
      padding-bottom: $space;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 80px;
        height: 5px;
        background-color: $green-color;
      }
    }
  }
}

.mobile {
  .persyaratan_biaya {
    flex-direction: column;
    .persyaratan__title,
    .biaya__title {
      font-size: 1.2rem;
    }
  }
}

@media #{$mq-large} {
  .persyaratan_biaya {
    padding: 4% 18% !important;
  }
}
</style>