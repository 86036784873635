<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 128"
    width="64px"
    height="64px"
  >
    <path
      fill="#ff697b"
      d="M86.2,109H41.8C32,109,24,101,24,91.2V46.8C24,37,32,29,41.8,29h44.4C96,29,104,37,104,46.8v44.4C104,101,96,109,86.2,109z"
    />
    <path
      fill="#fff"
      d="M24,46.8V58h80V46.8C104,37,96,29,86.2,29H41.8C32,29,24,37,24,46.8z"
    />
    <path
      fill="#444b54"
      d="M86.2,112H41.8C30.3,112,21,102.7,21,91.2V46.8C21,35.3,30.3,26,41.8,26h44.4c11.5,0,20.8,9.3,20.8,20.8v44.4C107,102.7,97.7,112,86.2,112z M41.8,32C33.6,32,27,38.6,27,46.8v44.4c0,8.1,6.6,14.8,14.8,14.8h44.4c8.1,0,14.8-6.6,14.8-14.8V46.8c0-8.1-6.6-14.8-14.8-14.8H41.8z"
    />
    <path fill="#fff" d="M64 54A15 15 0 1 0 64 84A15 15 0 1 0 64 54Z" />
    <path
      fill="#444b54"
      d="M64,87c-9.9,0-18-8.1-18-18c0-1.7,1.3-3,3-3s3,1.3,3,3c0,6.6,5.4,12,12,12s12-5.4,12-12s-5.4-12-12-12c-1.7,0-3-1.3-3-3s1.3-3,3-3c9.9,0,18,8.1,18,18S73.9,87,64,87z"
    />
    <path
      fill="#444b54"
      d="M53.8 60.9c-.2 0-.4 0-.6-.1s-.4-.1-.6-.2c-.2-.1-.4-.2-.5-.3-.2-.1-.3-.2-.5-.4-.1-.1-.3-.3-.4-.5-.1-.2-.2-.3-.3-.5-.1-.2-.1-.4-.2-.6 0-.2-.1-.4-.1-.6 0-.2 0-.4.1-.6 0-.2.1-.4.2-.6.1-.2.2-.4.3-.5.1-.2.2-.3.4-.5.1-.1.3-.3.5-.4.2-.1.3-.2.5-.3.2-.1.4-.1.6-.2.4-.1.8-.1 1.2 0 .2 0 .4.1.6.2.2.1.4.2.5.3.2.1.3.2.4.4.1.1.3.3.4.5.1.2.2.3.3.5.1.2.1.4.2.6 0 .2.1.4.1.6 0 .2 0 .4-.1.6 0 .2-.1.4-.2.6-.1.2-.2.4-.3.5-.1.2-.2.3-.4.5-.1.1-.3.3-.4.4-.2.1-.3.2-.5.3-.2.1-.4.1-.6.2S54 60.9 53.8 60.9zM86 43A4 4 0 1 0 86 51 4 4 0 1 0 86 43zM19 112.3c-1.6 0-3-1.3-3-3 0-1.7 1.3-3 3-3l90-.5c0 0 0 0 0 0 1.6 0 3 1.3 3 3 0 1.7-1.3 3-3 3L19 112.3C19 112.3 19 112.3 19 112.3zM124 111.8c-.8 0-1.6-.3-2.1-.9-.1-.1-.3-.3-.4-.5-.1-.2-.2-.3-.3-.5-.1-.2-.1-.4-.2-.6 0-.2-.1-.4-.1-.6 0-.2 0-.4.1-.6 0-.2.1-.4.2-.6.1-.2.2-.4.3-.5.1-.2.2-.3.4-.5.1-.1.3-.3.4-.4.2-.1.3-.2.5-.3.2-.1.4-.1.6-.2.4-.1.8-.1 1.2 0 .2 0 .4.1.6.2.2.1.4.2.5.3.2.1.3.2.4.4.6.6.9 1.3.9 2.1 0 .2 0 .4-.1.6 0 .2-.1.4-.2.6-.1.2-.2.4-.3.5-.1.2-.2.3-.4.5C125.6 111.5 124.8 111.8 124 111.8z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
