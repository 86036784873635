<template
  ><svg
    width="100%"
    height="100%"
    viewBox="0 0 645 570"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:2;"
  >
    <path
      d="M615.539,270.997c-12.915,17.296 -39.724,19.116 -39.724,19.116c0,0 -5.87,-26.221 7.045,-43.517c12.915,-17.296 39.724,-19.116 39.724,-19.116c0,0 5.87,26.221 -7.045,43.517Z"
      style="fill:#e6e6e6;fill-rule:nonzero;"
    />
    <path
      d="M549.747,277.499c8.523,11.413 26.213,12.614 26.213,12.614c0,0 3.873,-17.303 -4.649,-28.716c-8.522,-11.413 -26.213,-12.614 -26.213,-12.614c0,0 -3.873,17.303 4.649,28.716Z"
      style="fill:#e6e6e6;fill-rule:nonzero;"
    />
    <rect
      x="126.617"
      y="39.703"
      width="467.984"
      height="248.739"
      style="fill:#e6e6e6;fill-rule:nonzero;"
    />
    <path
      d="M563.625,301.403l-220.013,98.218l-186.018,-98.218c-3.792,-0.004 -6.91,-3.496 -6.914,-7.741l0,-220.528c0.004,-4.245 3.122,-7.737 6.914,-7.741l406.031,0c3.791,0.004 6.909,3.496 6.913,7.741l0,220.528c-0.004,4.245 -3.122,7.737 -6.913,7.741Z"
      style="fill:#fff;fill-rule:nonzero;"
    />
    <path
      d="M595.54,48.769l-469.862,0l0,-10.775c0.008,-7.36 6.062,-13.413 13.421,-13.421l443.02,0c7.36,0.008 13.413,6.061 13.421,13.421l0,10.775Z"
      style="fill:#afafaf;fill-rule:nonzero;"
    />
    <path
      d="M253.636,200.763l-72.267,0c-3.792,-0.004 -6.91,-3.122 -6.914,-6.914l0,-47.508c0.004,-3.791 3.122,-6.91 6.914,-6.914l72.267,0c3.791,0.004 6.91,3.123 6.914,6.914l0,47.508c-0.004,3.792 -3.123,6.91 -6.914,6.914Z"
      style="fill:#e6e6e6;fill-rule:nonzero;"
    />
    <path
      d="M398.3,147.118l-86.112,0c-0.795,0 -1.45,-1.736 -1.45,-3.845c0,-2.11 0.655,-3.846 1.45,-3.846l86.112,0c0.795,0 1.45,1.736 1.45,3.846c0,2.109 -0.655,3.845 -1.45,3.845Z"
      style="fill:#0ead69;fill-rule:nonzero;"
    />
    <path
      d="M356.547,118.976l-45.05,0c-0.417,0 -0.759,-1.736 -0.759,-3.846c0,-2.109 0.342,-3.845 0.759,-3.845l45.05,0c0.416,0 0.758,1.736 0.758,3.845c0,2.11 -0.342,3.846 -0.758,3.846Z"
      style="fill:#0ead69;fill-rule:nonzero;"
    />
    <path
      d="M542.918,174.628l-228.334,0c-2.11,0 -3.846,-1.736 -3.846,-3.845c0,-2.11 1.736,-3.845 3.846,-3.845l228.334,0c2.11,0 3.846,1.735 3.846,3.845c0,2.109 -1.736,3.845 -3.846,3.845Z"
      style="fill:#e6e6e6;fill-rule:nonzero;"
    />
    <path
      d="M540.753,234.325l-356.913,0c-3.298,0 -6.011,-1.736 -6.011,-3.846c0,-2.109 2.713,-3.845 6.011,-3.845l356.913,0c3.297,0 6.011,1.736 6.011,3.845c0,2.11 -2.714,3.846 -6.011,3.846Z"
      style="fill:#e6e6e6;fill-rule:nonzero;"
    />
    <path
      d="M547.511,295.148l-356.913,0c-3.297,0 -6.011,-1.736 -6.011,-3.846c0,-2.109 2.714,-3.845 6.011,-3.845l356.913,0c3.297,0 6.011,1.736 6.011,3.845c0,2.11 -2.714,3.846 -6.011,3.846Z"
      style="fill:#e6e6e6;fill-rule:nonzero;"
    />
    <path
      d="M542.918,202.139l-228.334,0c-2.11,0 -3.846,-1.736 -3.846,-3.846c0,-2.11 1.736,-3.845 3.846,-3.845l228.334,0c2.11,0 3.846,1.735 3.846,3.845c0,2.11 -1.736,3.846 -3.846,3.846Z"
      style="fill:#e6e6e6;fill-rule:nonzero;"
    />
    <path
      d="M540.789,261.835l-354.733,0c-3.277,0 -5.974,-1.736 -5.974,-3.845c0,-2.11 2.697,-3.846 5.974,-3.846l354.733,0c3.278,0 5.975,1.736 5.975,3.846c0,2.109 -2.697,3.845 -5.975,3.845Z"
      style="fill:#e6e6e6;fill-rule:nonzero;"
    />
    <path
      d="M419.548,556.311l-12.26,0l-5.832,-47.288l18.094,0l-0.002,47.288Z"
      style="fill:#a0616a;fill-rule:nonzero;"
    />
    <path
      d="M398.531,552.808l23.644,0l0,14.887l-38.531,0c0,-0.001 0,-0.001 0,-0.001c0,-8.166 6.72,-14.886 14.887,-14.886Z"
      style="fill:#2f2e41;fill-rule:nonzero;"
    />
    <path
      d="M470.548,556.311l-12.26,0l-5.832,-47.288l18.094,0l-0.002,47.288Z"
      style="fill:#a0616a;fill-rule:nonzero;"
    />
    <path
      d="M449.531,552.808l23.644,0l0,14.887l-38.531,0c0,-0.001 0,-0.001 0,-0.001c0,-8.166 6.72,-14.886 14.887,-14.886Z"
      style="fill:#2f2e41;fill-rule:nonzero;"
    />
    <path
      d="M468.987,545.443l-12.48,0c-2.324,-0.013 -4.28,-1.813 -4.484,-4.128l-10.576,-127.399c-0.065,-0.773 -0.719,-1.375 -1.495,-1.375c-0.756,0 -1.4,0.572 -1.489,1.323l-15.19,127.611c-0.269,2.252 -2.2,3.967 -4.468,3.968l-15.324,0c-2.458,-0.004 -4.482,-2.018 -4.498,-4.476c-0.034,-8.016 -0.088,-16.09 -0.143,-24.165c-0.486,-71.922 -0.989,-146.292 14.029,-175.428l0.137,-0.265l54.192,-0.927l0.136,0.298c16.396,35.83 11.42,115.875 6.607,193.283l-0.463,7.459c-0.15,2.357 -2.129,4.217 -4.491,4.221Z"
      style="fill:#2f2e41;fill-rule:nonzero;"
    />
    <circle cx="443.314" cy="219.864" r="24.561" style="fill:#a0616a;" />
    <path
      d="M409.829,345.623l0.676,-28.16l-4.872,-21.971c-2.494,-11.027 0.436,-22.606 7.875,-31.119c7.238,-8.47 18.112,-12.979 29.22,-12.115l0,0c0.354,0.027 0.708,0.057 1.064,0.089c9.457,0.851 18.173,5.513 24.131,12.907c6.083,7.436 8.887,17.034 7.764,26.574l-5.894,51.517l-59.964,2.278Z"
      style="fill:#0ead69;fill-rule:nonzero;"
    />
    <path
      d="M388.965,396.093c2.439,-1.904 3.867,-4.831 3.867,-7.926c0,-2.854 -1.215,-5.578 -3.34,-7.484l13.828,-32.951l-18.25,3.428l-10.334,30.765c-1.396,1.78 -2.155,3.978 -2.155,6.24c0,5.546 4.564,10.11 10.11,10.11c2.277,0 4.488,-0.769 6.274,-2.182Z"
      style="fill:#a0616a;fill-rule:nonzero;"
    />
    <path
      d="M493.308,400.043c-2.176,-1.909 -3.426,-4.667 -3.426,-7.561c0,-3.054 1.391,-5.948 3.777,-7.855l-11.932,-33.684l18.026,4.461l8.567,31.303c1.18,1.695 1.813,3.712 1.813,5.777c0,5.546 -4.564,10.11 -10.11,10.11c-2.475,0 -4.865,-0.908 -6.715,-2.551l0,0Z"
      style="fill:#a0616a;fill-rule:nonzero;"
    />
    <path
      d="M391.16,379.947c-0.634,0 -1.261,-0.134 -1.839,-0.393l-10.521,-4.713c-2.085,-0.931 -3.146,-3.312 -2.444,-5.485l17.648,-54.82l6.82,-41.299c0.881,-5.333 5.538,-9.283 10.942,-9.283c6.084,0 11.091,5.006 11.091,11.09c0,0.851 -0.098,1.699 -0.292,2.528l-10.365,44.292l-16.735,54.895c-0.575,1.889 -2.33,3.189 -4.305,3.188l0,0Z"
      style="fill:#0ead69;fill-rule:nonzero;"
    />
    <path
      d="M491.522,379.947c-1.974,0.001 -3.729,-1.299 -4.305,-3.188l-16.726,-54.864l-10.374,-44.323c-0.194,-0.829 -0.292,-1.677 -0.292,-2.528c0,-6.084 5.007,-11.091 11.091,-11.091c5.405,0 10.062,3.951 10.943,9.284l6.837,41.372l17.631,54.747c0.702,2.173 -0.359,4.554 -2.445,5.485l-10.521,4.713c-0.578,0.259 -1.205,0.393 -1.839,0.393Z"
      style="fill:#0ead69;fill-rule:nonzero;"
    />
    <path
      d="M438.905,190.141c-4.252,1.488 -10.829,5.402 -12.414,10.908c-1.281,4.449 -2.361,8.449 -1.416,12.981c0.945,4.532 2.293,9.055 2.148,13.682c-0.145,4.628 -0.585,8.983 -2.734,13.084c-1.283,2.448 -3.474,4.676 -5.641,6.395c-3.378,2.68 -13.12,11.936 -14.85,14.661c-2.522,3.973 -2.152,6.56 -1.045,9.462c0.932,2.442 3.237,3.633 5.308,5.226c2.058,1.546 4.396,2.678 6.885,3.333c19.146,6.12 39.537,7.282 59.255,3.376c3.918,-0.781 7.951,-1.838 10.995,-4.425c3.045,-2.588 4.786,-7.117 3.036,-10.709c-2.623,-5.384 -10.953,-5.691 -13.856,-10.931c-1.741,-3.142 -0.94,-7.001 -0.573,-10.575c0.947,-9.231 3.131,-20.014 0.838,-29.005c-2.072,-8.124 -6.369,-18.871 -13,-24c-9.527,-7.37 -22.936,-3.463 -22.936,-3.463Z"
      style="fill:#2f2e41;fill-rule:nonzero;"
    />
    <path
      d="M643.463,569.058l-350,0c-0.549,0 -1,-0.451 -1,-1c0,-0.548 0.451,-1 1,-1l350,0c0.548,0 1,0.452 1,1c0,0.549 -0.452,1 -1,1Z"
      style="fill:#ccc;fill-rule:nonzero;"
    />
    <path
      d="M90.03,102.315c-12.915,17.296 -39.724,19.117 -39.724,19.117c0,0 -5.869,-26.222 7.046,-43.518c12.915,-17.296 39.723,-19.116 39.723,-19.116c0,0 5.87,26.221 -7.045,43.517Z"
      style="fill:#e6e6e6;fill-rule:nonzero;"
    />
    <path
      d="M24.239,108.817c8.522,11.413 26.212,12.615 26.212,12.615c0,0 3.873,-17.303 -4.649,-28.716c-8.522,-11.413 -26.212,-12.615 -26.212,-12.615c0,0 -3.874,17.303 4.649,28.716Z"
      style="fill:#e6e6e6;fill-rule:nonzero;"
    />
    <path
      d="M157.667,244.345l-108.427,0c-6.991,-0.008 -12.741,-5.758 -12.749,-12.749l0,-128.325c0.006,-5.229 4.307,-9.529 9.535,-9.535l114.703,0c5.311,0.006 9.68,4.375 9.686,9.687l0,128.173c-0.007,6.991 -5.757,12.741 -12.748,12.749Z"
      style="fill:#0ead69;fill-rule:nonzero;"
    />
    <path
      d="M157.667,244.345l-108.427,0c-6.991,-0.008 -12.741,-5.758 -12.749,-12.749l0,-128.325c0.006,-5.229 4.307,-9.529 9.535,-9.535l114.703,0c5.311,0.006 9.68,4.375 9.686,9.687l0,128.173c-0.007,6.991 -5.757,12.741 -12.748,12.749Zm-111.641,-149.152c-4.429,0.005 -8.073,3.648 -8.078,8.078l0,128.325c0.007,6.192 5.1,11.285 11.292,11.292l108.427,0c6.192,-0.007 11.284,-5.1 11.291,-11.292l0,-128.173c-0.005,-4.513 -3.717,-8.225 -8.229,-8.23l-114.703,0Z"
      style="fill:none;fill-rule:nonzero;stroke:#dfdfdf;stroke-width:4.51px;"
    />
    <circle cx="103.453" cy="153.396" r="22.382" style="fill:#fff;" />
    <path
      d="M139.31,207.066l-71.713,0l0,-0.228c0.015,-13.651 11.243,-24.878 24.894,-24.894l21.925,0c13.651,0.016 24.879,11.243 24.894,24.894l0,0.228Z"
      style="fill:#fff;fill-rule:nonzero;"
    />
    <circle
      id="af1d3ae0-b86a-44f8-8168-f2f371efc620"
      cx="578.607"
      cy="49.631"
      r="38.614"
      style="fill:#0ead69;stroke:#fff;stroke-width:4.51px;stroke-linecap:butt;"
    />
    <path
      id="b4906b3b-71df-4c7e-84d8-52e6769e6d26"
      d="M574.78,64.361c-0.868,0.002 -1.714,-0.279 -2.409,-0.8l-0.043,-0.033l-9.075,-6.941c-0.997,-0.763 -1.582,-1.949 -1.582,-3.204c0,-2.212 1.82,-4.032 4.032,-4.032c0.889,0 1.753,0.293 2.457,0.835l5.878,4.507l13.89,-18.121c1.346,-1.755 3.897,-2.092 5.652,-0.746l0.001,0.001l-0.086,0.119l0.088,-0.119c1.753,1.347 2.09,3.897 0.746,5.653l-16.337,21.304c-0.766,0.994 -1.953,1.576 -3.208,1.573l-0.004,0.004Z"
      style="fill:#fff;fill-rule:nonzero;"
    />
    <path
      d="M150.676,282.914l0.461,15.225l192.845,101.036l-193.306,-116.261Z"
      style="fill:#f1f1f1;"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
