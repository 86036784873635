<template>
  <section class="agenda" :class="isMobile ? 'mobile' : ''">
    <h1 class="agenda-title">AGENDA</h1>
    <div class="agenda-content">
      <el-timeline>
        <el-timeline-item
          v-for="(item, index) in agenda"
          :key="index"
          :timestamp="item.tanggal"
          placement="top"
        >
          <el-card class="card-timeline">
            <h4 class="margin-remove">{{ item.judul }}</h4>
            <ul>
              <li><i class="el-icon-map-location"></i> {{ item.lokasi }}</li>
              <li
                style="display: flex; justify-content: space-between"
                :style="
                  isMobile
                    ? 'flex-direction:column; justify-content: unset; align-items: flex-start;'
                    : ''
                "
              >
                <span>
                  <i class="el-icon-date" style="margin-right: 10px"></i>
                  {{ item.tanggal_awal + " - " + item.tanggal_akhir }}
                </span>
                <span>
                  <i class="el-icon-time" style="margin-right: 10px"></i>
                  {{ item.waktu_buka }} -
                  {{ item.waktu_tutup }}
                </span>
              </li>
            </ul>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
  </section>
</template>

<script>
export default {
  name: "agenda-components",
  props: {
    agenda: Array,
  },
};
</script>

<style lang="scss" scoped>
.agenda {
  min-height: 350px;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  padding: 4% !important;
  position: relative;
  gap: $space * 2;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.4;
    background-image: url("~@/assets/Pattern.png");
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: contain;
  }
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 50%;
    height: 0;
    width: 0;
    border: solid transparent;
    opacity: 1;
    pointer-events: none;
    border-color: transparent;
    border-width: 35px;
    border-top-color: transparentize(darken(white, 25), 0.4);
    margin-left: -35px;
  }

  &-title {
    font-size: 2.5rem;
    margin: $space * 2 0;
    padding-bottom: $space;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 80px;
      height: 5px;
      background-color: $green-color;
    }
  }
  &-content {
    flex: 1;
    overflow: auto;
    z-index: 1;
    padding: $space;
    background-color: darken(white, 2);
    border: 1px solid darken(white, 10);
  }
}

.card {
  &-timeline {
    h4 {
      margin-bottom: $space / 2;
    }

    ul {
      display: flex;
      flex-direction: column;
      padding: 0;
      li {
        list-style: none;
        display: flex;
        gap: $space;
        align-items: center;
        margin-bottom: $space / 2;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media #{$mq-large} {
  .agenda {
    padding: 4% 18% !important;
  }
}

.mobile {
  .agenda {
    justify-content: flex-start;
    flex-direction: column;
    padding: $space * 2 4% !important;

    &-title {
      font-size: 1.2rem;
      align-self: flex-start;
      &:after {
        width: 40px;
      }
    }

    &-content {
      border: 1px solid darken(white, 10);
      background-color: darken(white, 5);
      padding: $space;
      overflow: unset;
      width: unset;
      align-self: unset;
      .el-timeline {
        padding: 0;
      }
    }
  }
}
</style>