<template>
  <div class="verify" v-loading="loading">
    <el-card class="card">
      <div class="anim">
        <lottie-animation
          v-if="status == 'success'"
          ref="anim"
          :loop="true"
          :autoPlay="true"
          :animationData="require('@/assets/animation/verifysuccess.json')"
        />
        <lottie-animation
          v-else
          ref="anim"
          :loop="true"
          :autoPlay="true"
          :animationData="require('@/assets/animation/verifyfailed.json')"
        />
      </div>
      <p v-if="status == 'success'">
        Akun anda sudah terverifikasi, silahkan login kembali!
      </p>
      <p v-else>Verifikasi gagal, silahkan coba kembali!</p>
      <el-button-group v-if="status == 'success'">
        <el-button @click="goHome" icon="el-icon-s-home">Home</el-button>
        <el-button @click="goLogin" icon="el-icon-user" type="primary"
          >Login</el-button
        >
      </el-button-group>
      <el-button @click="retry" v-else>Coba Kembali</el-button>
    </el-card>
  </div>
</template>

<script>
import LottieAnimation from "lottie-web-vue";
export default {
  name: "verify-page",
  data() {
    return {
      loading: false,
      status: "",
    };
  },
  props: {
    id: String,
  },
  components: {
    LottieAnimation,
  },
  mounted() {
    this.verify();
  },
  methods: {
    verify() {
      this.loading = true;
      this.$store
        .dispatch("setVerify", { id: this.id })
        .then(() => {
          this.loading = false;
          this.status = "success";
        })
        .catch((err) => {
          this.loading = false;
          this.status = "failed";
          return;
        });
    },
    goHome() {
      this.$router.push({ name: "Home" });
    },
    goLogin() {
      this.$router.push({ name: "Login" });
    },
    retry() {
      this.$router.go();
    },
  },
};
</script>

<style lang="scss" scoped>
.verify {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.anim {
  > div {
    height: 250px;
  }
}
</style>