<template>
  <component :is="layout" class="content">
    <router-view />
  </component>
</template>

<script>
const default_layout = "default-layout";
export default {
  computed: {
    layout() {
      return this.$route.meta.layout || default_layout;
    },
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  color: #2c3e50;
  display: flex;
  overflow: hidden;
}
</style>
