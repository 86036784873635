<template>
  <el-card class="box-login">
    <div class="header">
      <span>Reset Password</span>
    </div>
    <div class="body">
      <el-form ref="form" :rules="rules" :model="form">
        <el-form-item label="Password Baru" prop="passwordLama">
          <el-input
            placeholder="Password Baru"
            type="password"
            v-model="form.passwordLama"
          ></el-input>
        </el-form-item>
        <el-form-item label="Konfirmasi Password" prop="passwordBaru">
          <el-input
            placeholder="Konfirmasi Password"
            type="password"
            v-model="form.passwordBaru"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            style="width: 100%"
            :loading="loading"
            @click="handleReset"
            type="primary"
          >
            Ubah Password
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "reset-component",
  data() {
    return {
      loading: false,
      forget: false,
      form: {
        passwordLama: "",
        passwordBaru: "",
      },
      rules: {
        passwordLama: [
          {
            required: true,
            message: "Please input your old password",
            trigger: "blur",
          },
        ],
        passwordBaru: [
          {
            required: true,
            message: "Please input your new password",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    handleReset() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          this.$notify({
            title: "Error",
            message: "Lengkapi Form Inputan!",
            type: "error",
          });
          return;
        }

        this.loading = true;

        this.$store
          .dispatch("resetPassword", {
            id: this.$route.params.id,
            password: this.form.passwordLama,
            password_confirmation: this.form.passwordBaru,
          })
          .then((res) => {
            this.$notify({
              title: "Success",
              message: "Password anda berhasil di ubah!",
              type: "success",
            });
            this.loading = false;
            this.$router.push({ name: "Login" });
          })
          .catch((err) => {
            this.$notify({
              title: "Error",
              message: "Ganti Password Gagal, Silahkan Coba Kembali!",
              type: "error",
            });
            this.loading = false;
          });
      });
    },
  },
};
</script>

<style lang="scss">
.box-login {
  width: 380px;

  .el-card__body {
    padding: 0;
  }
}
</style>

<style lang="scss" scoped>
.body,
.header {
  padding: $space * 2;
}

.body {
  padding-top: 0;
  padding-bottom: 0;
}

.header {
  padding-bottom: 0;
}
.header {
  margin-bottom: $space * 2;
  span {
    font-weight: lighter;
    font-size: 2rem;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 30%;
      height: 4px;
      background-color: $green-color;
    }
  }
}

.register-button {
  background-color: darken(white, 4);
  padding: $space $space * 2;
  gap: $space / 2;
  display: flex;
  flex-direction: column;
  span {
    font-weight: bold;
    font-size: 0.875rem;
    color: darken(white, 60);
  }
  button {
    width: 100px;
  }
}
</style>
