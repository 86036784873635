<template>
  <div class="reset">
      <Reset />
  </div>
</template>

<script>
import Reset from "@/components/auth/boxReset.vue";
export default {
  name: "Reset-Password",
  components: {
      Reset
  },
  props: {
    id: String,
  },
};
</script>

<style lang="scss" scoped>
.reset{
    display: flex;
    flex: 1;
    border: 1px solid orange;
    align-items: center;
    justify-content: center;
}
</style>
