export default {
    methods: {
        formatRupiah(angka) {
            if (angka === null) {
                angka = 0;
            }
            if (angka !== undefined || angka !== null) {
                let numb = 0;
                let textVal1 = new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                }).format(angka);
                let textVal2 = new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                }).format(numb);
                let diff = textVal2.replace("0,00", "");
                return angka < 0
                    ? textVal1.replace("-" + diff, "(Rp ") + ")"
                    : textVal1;
            }
        },
    }
}