import axios from 'axios';
export default {
    name: "auth-module",
    state: {
        token: ""
    },
    getters: {
        getToken(state) {
            return state.token
        }
    },
    mutations: {
        setToken(state, payload) {
            state.token = payload
        }
    },
    actions: {
        setLogin(store, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    method: "post",
                    url: process.env.VUE_APP_BASE_API + "api/v1/ppdb/user/login",
                    data: {
                        username: payload.username,
                        password: payload.password
                    }
                }).then(res => {
                    store.commit("setToken", res.data.data.token);
                    localStorage.setItem("token", res.data.data.token);
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            });
        },

        setLoginGoogle(store, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    method: "post",
                    url: process.env.VUE_APP_BASE_API + "api/v1/ppdb/sociallogin/google",
                    data: payload
                }).then(res => {
                    store.commit("setToken", res.data.data.token);
                    localStorage.setItem("token", res.data.data.token);
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            });
        },
        setRegister(store, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    method: "post",
                    url: process.env.VUE_APP_BASE_API + "api/v1/ppdb/user/register",
                    data: {
                        username: payload.username,
                        password: payload.password
                    }
                }).then(res => {
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            });
        },
        setVerify(store, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    method: "post",
                    url: `${process.env.VUE_APP_BASE_API}api/v1/ppdb/user/verify/${payload.id}`,
                }).then(res => {
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            });
        },
        forgetPassword(store, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    method: "post",
                    url: process.env.VUE_APP_BASE_API + "api/v1/ppdb/user/forgot-password",
                    data: {
                        username: payload.username
                    }
                }).then(res => {
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            });
        },
        resetPassword(store, payload) {
            console.log(payload)
            return new Promise((resolve, reject) => {
                axios({
                    method: "post",
                    url: process.env.VUE_APP_BASE_API + `api/v1/ppdb/user/reset-password/${payload.id}`,
                    data: {
                        password: payload.password,
                        password_confirmation: payload.password_confirmation
                    }
                }).then(res => {
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            });
        },
    }
}
