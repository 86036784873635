import Vue from 'vue'
import Vuex from 'vuex'
// import Ppdb from '@/store/modules/ppdb.js'
import Auth from '@/store/modules/auth.js'
import Riwayat from '@/store/modules/riwayat.js'
import Ppdb from '../views/Ppdb.vue'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Riwayat,
    Auth,
    Ppdb
  },
  state: {
  },
  mutations: {
  },
  actions: {
  },
  
})
