<template>
  <section class="footer" :class="isMobile ? 'mobile' : ''">
    <ul class="address">
      <li>
        <h5>YAYASAN MA'HAD DARUN NAJAH AS-SALAFY</h5>
        <small
          >Jalan Pesantren (Manyar) no. 51 desa Ngijo kecamatan Karangploso
          Kabupaten Malang – Jawa Timur </small
        >
        <el-button @click="goPrivacy" type="text">
          (Kebijakan Privasi)
        </el-button>
      </li>

    </ul>
    <ul class="media-social">
      <li>
        <Facebooksvg />
      </li>
      <li>
        <Instagramsvg />
      </li>
      <li>
        <Twittersvg />
      </li>
    </ul>
  </section>
</template>

<script>
import { Facebooksvg, Instagramsvg, Twittersvg } from "@/components/svg";
export default {
  name: "footer-component",
  components: {
    Facebooksvg,
    Instagramsvg,
    Twittersvg,
  },
  methods: {
    goPrivacy() {
      this.$router.push({ name: "Privacy" });
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  border-top: 1px solid darken(white, 10);
  min-height: 50px;
  background-color: white;
  padding: $space 4% !important;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .address,
  .media-social {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
    align-items: center;
    li {
      list-style: none;
    }
  }
  .address {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 60%;
    h5 {
      margin: 0;
    }
  }
  .media-social {
    li {
      margin-left: $space * 2;
      &:first-child {
        margin-left: 0;
      }
      svg {
        height: 32px;
        width: 32px;
      }
    }
  }
}

.mobile {
  .footer {
    justify-content: flex-start;
    flex-direction: column;

    .address {
      margin-bottom: $space;
    }

    .media-social {
      li {
        margin-left: $space;
        &:first-child {
          margin-left: 0;
        }
        svg {
          height: 24px;
          width: 24px;
        }
      }
    }
  }
}
</style>