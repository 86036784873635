import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';
import Home from '../views/Home.vue'
import Riwayat from '../views/Riwayat.vue'
import Verifikasi from '../views/Verify.vue'
import Reset from '../views/Reset.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    name: "Login",
    meta: { layout: 'auth-layout' },
    component: () => import('@/views/Authentication.vue')
  },
  {
    path: "/login/callback/google",
    name: "LoginGoogle",
    meta: { layout: 'auth-layout' },
    component: () => import('@/views/Authentication.vue')
  },
  {
    path: '/daftar-baru',
    name: "Register",
    meta: { layout: 'auth-layout' },
    component: () => import('@/views/Register.vue')
  },
  {
    path: '/pembayaran',
    name: "Pembayaran",
    component: () => import('@/views/Pembayaran.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/riwayat-pendaftaran',
    name: 'Riwayat',
    component: Riwayat
  },
  {
    path: '/user/verify/:id',
    name: 'Verify',
    props: true,
    component: Verifikasi
  },
  {
    path: '/user/reset-password/:id',
    name: "Reset-Password",
    props: true,
    component: Reset    
  },
  {
    path: '/pendaftaran',
    name: "Ppdb",
    component: () => import('@/views/Ppdb.vue')
  },
  {
    path: '/privacy-policy',
    name: "Privacy",
    component: () => import('@/views/Privacy.vue')
  },
  {
    path: '/perbaruan/:id',
    name: "Edit-Ppdb",
    props: true,
    component: () => import('@/views/Ppdb.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  if (to.name == 'Ppdb' && (token == null || token == 'undefined')) next({ name: 'Login' })
  else {
    store.commit("setToken", token);
    next()
  }
})

export default router
