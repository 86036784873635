<template>
  <div
    v-loading.fullscreen.lock="loading"
    class="home"
    :class="isMobile ? 'home-mobile' : ''"
  >
    <section class="alert-info" v-if="!loading && status !== 'buka'">
      <i class="el-icon-message-solid margin-right"></i> Pendaftaran belum kami
      buka
    </section>

    <Banner
      :onClick="handlePPBD"
      :status="status"
      :background="background"
      :video="video"
    />
    <Biaya :biaya="biaya" :persyaratan="persyaratan" />
    <Agenda :agenda="agenda" />

    <section class="register">
      <h1><b>Sekolah</b> yang tepat untuk sarana belajar kamu!</h1>
      <el-button round :disabled="status != 'buka'" @click="handlePPBD" plain
        >Daftar Sekarang</el-button
      >
    </section>
    <section class="maps">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3951.9120282559816!2d112.6091174153328!3d-7.904259080848726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7881e6fdee4285%3A0x555ffafa0451cd15!2sPPAI%20Darun%20Najah!5e0!3m2!1sen!2sid!4v1607021479037!5m2!1sen!2sid"
        frameborder="0"
        style="border: 0"
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      ></iframe>
    </section>
  </div>
</template>

<script>
import Informationsvg from "@/components/information.vue";
import Banner from "@/components/home/banner.vue";
import Biaya from "@/components/home/biaya.vue";
import Agenda from "@/components/home/agenda.vue";
import { gsap } from "gsap";
export default {
  name: "Home",
  components: {
    Informationsvg,
    Banner,
    Biaya,
    Agenda,
  },
  data() {
    return {
      persyaratan: [],
      agenda: [],
      unggulan: [],
      biaya: [],
      video: "",
      status: "tutup",
      background: "",
      loading: false,
    };
  },
  async mounted() {
    await this.fetch();
    this.startAnimation();
  },
  methods: {
    startAnimation() {
      gsap.from(".greeting", {
        x: -10,
        duration: 1.5,
        ease: "power3",
      });
      gsap.from(".ilustration", {
        x: 20,
        duration: 1.5,
        ease: "power3",
      });
    },
    handlePPBD() {
      if (this.status == "buka") {
        this.$router.push({ name: "Ppdb" });
      } else if (this.status == "tutup") {
        this.$message({
          message: "Pendaftaran belum kami buka",
          type: "warning",
        });
      }
    },
    async fetch() {
      this.loading = true;
      const agenda = this.$axios.get(
        process.env.VUE_APP_BASE_API + "api/v1/ppdb/agenda"
      );
      const unggulan = this.$axios.get(
        process.env.VUE_APP_BASE_API + "api/v1/ppdb/programunggulan"
      );
      const background = this.$axios.get(
        process.env.VUE_APP_BASE_API + "api/v1/ppdb/banner"
      );
      const status = this.$axios.get(
        process.env.VUE_APP_BASE_API + "api/v1/ppdb/ppdbstatus"
      );
      const persyaratan = this.$axios.get(
        process.env.VUE_APP_BASE_API + "api/v1/ppdb/resource/persyaratanppdb"
      );
      const biaya = this.$axios.get(
        process.env.VUE_APP_BASE_API + "api/v1/ppdb/resource/biayapendaftaran"
      );
      const video = this.$axios.get(
        process.env.VUE_APP_BASE_API + "api/v1/ppdb/video"
      );
      await this.$axios
        .all([agenda, unggulan, background, status, persyaratan, biaya, video])
        .then(
          this.$axios.spread((...response) => {
            this.agenda = response[0].data.data;
            this.unggulan = response[1].data.data;
            this.background = response[2].data.data.isi;
            this.status = response[3].data.data.isi;
            this.persyaratan = response[4].data.data;
            this.biaya = response[5].data.data;
            this.video = response[6].data.data.isi;
            this.loading = false;
          })
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-info {
  padding: $space;
  color: white;
  font-size: 0.87rem;
  background-color: $alert-color;
}

.dots {
  position: absolute;
  height: 110px;
  top: 80px;
  left: 10%;
}
.timeline {
  padding: 0 $space;
}

.register {
  min-height: 200px;
  background-color: $green-color;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("~@/assets/pattern-birds.png");
    background-size: contain;
    opacity: 0.7;
    z-index: 0;
  }

  h1 {
    font-size: 2rem;
    font-weight: 500;
    margin: $space 0;
  }
  > * {
    z-index: 1;
  }
}
.maps {
  > * {
    width: 100%;
    height: 250px;
  }
}

.home-mobile {
  .register {
    padding: $space * 3 4% !important;
    min-height: 130px;
    h1 {
      font-size: 1.4rem;
      text-align: center;
    }
  }
}

.table {
  border-radius: $space;
  border: 1px solid darken(white, 30);
}

@media #{$mq-large} {
  .unggulan,
  .agenda,
  .persyaratan_biaya {
    padding: 4% 18% !important;
  }
  .footer {
    padding: $space 18% !important;
  }
  .ilustration {
    right: 18%;
  }
}
</style>
