import axios from 'axios';
export default {
    name: "riwayat-module",
    state: {
        riwayat: [],
        pembayaran: [],
        selected: {}
    },
    getters: {
        getRiwayat(state){
            return state.riwayat
        },
        getPembayaran(state){
            return state.pembayaran
        },
        getSelectedRiwayat(state){
            return state.selected
        }
    },
    mutations: {
        setRiwayat(state, payload) {
            state.riwayat = payload
        },
        setPembayaran(state, payload) {
            state.pembayaran = payload
        },
        setSelectedRiwayat(state, payload){
            state.selected = payload
        }
    },
    actions: {
        listPendaftaran(store, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    method: "get",
                    url: `${process.env.VUE_APP_BASE_API}api/v1/ppdb/pendaftaran/list`,
                    headers: {
                        "Authorization" : 'Bearer '+ payload
                    }
                }).then(res => {
                    store.commit("setRiwayat", res.data.data);
                    resolve(true)
                }).catch(err => {
                    reject(err)
                })
            });
        },
        detailRiwayat(store, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    method: "get",
                    url: `${process.env.VUE_APP_BASE_API}api/v1/ppdb/pendaftaran/${payload.id}`,
                    headers: {
                        "Authorization" : 'Bearer '+ payload.token
                    }
                }).then(res => {
                    store.commit("setSelectedRiwayat", res.data.data);
                    resolve(true)
                }).catch(err => {
                    reject(err)
                })
            });
        },
        detailSiswaNis(store, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    method: "get",
                    url: `${process.env.VUE_APP_BASE_API}api/v1/ppdb/pendaftaranbynis/${payload.id}`,
                    headers: {
                        "Authorization" : 'Bearer '+ payload.token
                    }
                }).then(res => {
                    store.commit("setSelectedRiwayat", res.data.data);
                    resolve(true)
                }).catch(err => {
                    reject(err)
                })
            });
        },
        listPembayaran(store, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    method: "get",
                    url: `${process.env.VUE_APP_BASE_API}api/v1/ppdb/pembayaranpendaftaran`,
                    headers: {
                        "Authorization" : 'Bearer '+ payload
                    }
                }).then(res => {
                    store.commit("setPembayaran", res.data.data);
                    console.log(res.data.data)
                    resolve(true)
                }).catch(err => {
                    reject(err)
                })
            });
        },
    }
}
