<template>
  <div class="tip">
    <h4 class="tip__title">{{ title }}</h4>
    <p class="tip__desc">{{ messages }}</p>
  </div>
</template>

<script>
export default {
  name: "tip-component",
  props: {
    title: String,
    messages: String,
  },
};
</script>

<style lang="scss" scoped>
.tip {
  min-height: 50px;
  position: relative;
  overflow: hidden;
  background-color: transparentize($color: $green-color, $amount: 0.9);
  border-radius: $space / 2;
  padding: $space $space * 2;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 5px;
    background-color: transparentize($color: $green-color, $amount: 0.6);
  }

  &__title,
  &__desc {
    margin: 0;
  }

  &__desc {
    font-size: 0.85rem;
  }
}
</style>
