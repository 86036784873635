<template>
  <div class="login-wrapper" :class="isMobile ? 'mobile' : ''">
    <slot />
  </div>
</template>

<script>
export default {
  name: "authLayout",
};
</script>

<style lang="scss" scoped>
.login {
  &-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}

.bodyContent {
  flex: 1;
}

.mobile {
  overflow-x: hidden;
}
</style>
