<template>
  <div id="app" class="layout-wrapper" :class="isMobile ? 'mobile' : ''">
    <Navigation />
    <div class="bodyContent">
      <slot />
    </div>
    <Footer />
  </div>
</template>

<script>
import Navigation from "@/components/navigation.vue";
import Footer from "@/components/home/footer.vue";
export default {
  name: "desktopLayout",
  components: {
    Navigation,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.layout {
  &-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}

.bodyContent {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.mobile {
  overflow-x: hidden;
}
</style>
